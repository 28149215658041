import React from "react";
import 'keen-slider/keen-slider.min.css'
import KeenSlider from 'keen-slider'
import { useKeenSlider } from 'keen-slider/react'

import './CidadeEscolhida.css';
import reiDoGado from '../../img/logoRestaurantes/carousel-cidade/rei-do-gado.png'


import redLocationIcon from '../../img/Icons/red-location-icon.png'

function CidadeEscolhida({cidade, siglaEstado, carouselCidade}){

    const infoRestaurantes = carouselCidade

    const perView = parseFloat(infoRestaurantes.length) - 0.5
    const perViewMobile = parseFloat(infoRestaurantes.length) - 1.5

    const [sliderRef] = useKeenSlider(
        {
          loop: true,
          mode: "free-snap",
          slides: { perView: parseFloat(perView), spacing: 16 },
          
     
          breakpoints: {
            'only screen and (min-width: 360px) and (max-width: 1192px)': {
                loop: true,
                mode: "free-snap",
                slides: {
                  perView: parseFloat(perViewMobile),
                  spacing: 16,
                },
            },
          },
        },
        [
          (slider) => {
            let timeout
            let mouseOver = false
            function clearNextTimeout() {
              clearTimeout(timeout)
            }
            function nextTimeout() {
              clearTimeout(timeout)
              if (mouseOver) return
              timeout = setTimeout(() => {
                slider.next()
              }, 2000)
            }
            slider.on("created", () => {
              slider.container.addEventListener("mouseover", () => {
                mouseOver = true
                clearNextTimeout()
              })
              slider.container.addEventListener("mouseover", () => {
                mouseOver = false
                nextTimeout()
              })
              slider.container.addEventListener("mouseout", () => {
                mouseOver = false
                nextTimeout()
              })
              nextTimeout()
            })
            slider.on("dragStarted", clearNextTimeout)
            slider.on("animationStarted", nextTimeout)
            slider.on("animationEnded", nextTimeout)
            slider.on("updated", nextTimeout)
          },
        ]
    )

    return(
        <div>                
            <div className='cidade-escolhida-div'>
                <img className="red-location-icon" src={redLocationIcon} alt="Icone localização"/>
                <span className="cidade-escolhida">{cidade}/{siglaEstado}</span>
            </div>

            <span className="faça-como">
                Faça como:
            </span>

            <div className="carousel-cidade">

                <div ref={sliderRef} className="keen-slider ">
                  
                  {infoRestaurantes.map((item, index) => 
                  <div className="keen-slider__slide container-rest"  key={index}> <img className="rest-cidade-img" src={item.url} alt="Logo Parceiros"/>
                      
                      <span className="nome-rest">
                          {item.nome}
                      </span>
                
                  </div>
                  )}
                </div>

            </div>

        </div>
    )
}

export default CidadeEscolhida;