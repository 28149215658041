import React from 'react';
import './Footer.css'

/* import de imagens e icons*/
import logoBranco from "../../img/logo/logobranco.png"
import facebookIcon from "../../img/redes-sociais-icons/facebook.png"
import twitterIcon from "../../img/redes-sociais-icons/twitter.png"
import instagramIcon from "../../img/redes-sociais-icons/instagram.png"
import linkedinIcon from "../../img/redes-sociais-icons/linkedin.png"
import googlePlayIcon from "../../img/redes-sociais-icons/googleplay.png"
import appStoreIcon from "../../img/redes-sociais-icons/appstore.png"


function Footer(){
    return(
        <div className="Footerdesktop">

            <div className='footer-container'>
                
                <div>
                    <img className="logocompletobranco" src={logoBranco} alt="Logo appedidos"/>
                </div>

                <div className="links">

                    <div className="footer-appedidos-texts ">

                        <span className="appedidos-title">
                            appedidos
                        </span>

                        <span className="div-sobre-nos">
                            <a className="text-sobre-nos" target="_blank" href="https://www.appedidos.com.br/sobre-nos">
                                Sobre nós
                            </a>
                        </span>

                        <span className="div-politica">
                            <a className="text-politica" target="_blank" href="https://www.appedidos.com.br/politica-de-privacidade" >
                                Política de privacidade
                            </a>
                        </span>

                        <span className="div-termos">
                            <a className="text-termos" target="_blank" href="https://www.appedidos.com.br/termos-e-condicoes" >
                                Termos e condições
                            </a>
                        </span>
                    </div>

                    <div className="footer-restaurante-texts ">

                        <span className="restaurante-title">
                            Restaurante
                        </span>

                        {/* <span className="div-blog">
                            <a className='text-blog' target="_blank" href="https://www.guiadodelivery.com.br/">
                                Blog
                            </a>
                        </span> */}

                        <span className="div-portal">
                            <a className='text-portal' target="_blank" href="https://www.appedidos.com.br/painel/funcionarios/login">
                                Portal do Restaurante
                            </a>
                        </span>

                        
                            <a target="_blank" href='https://cadastro.appedidos.com.br/' className='text-cadastrar footer-btn-cadastrar'>
                                Cadastrar meu restaurante
                            </a>
                        
                    </div>

                    <div className="footer-redes-sociais ">

                        <span className="redes-sociais-title">
                            Redes Sociais
                        </span>

                        <span className="icons-container">

                            <a target="_blank" href='https://www.facebook.com/appedidos/'>

                                <img className="icon-redes-sociais" src={facebookIcon} alt="Icon Facebook"/>

                            </a>

                            <a target="_blank" href="https://twitter.com/appedidos">

                                <img className="icon-redes-sociais" src={twitterIcon} alt="Icon Twitter"/>

                            </a>

                            <a target="_blank" href="https://www.instagram.com/appedidos/">

                                <img className="icon-redes-sociais" src={instagramIcon} alt="Icon Instagram"/>

                            </a>

                            <a target="_blank" href="https://www.linkedin.com/company/appedidos/">

                                <img className="icon-redes-sociais" src={linkedinIcon} alt="Icon Linkedin"/>

                            </a>

                        </span>

                    </div>
                </div>

                <div className="footer-gray-line"></div>

                <div className='baixe-o-app-container'>

                    <span class="baixe-o-app">
                        Baixe o app
                    </span>

                    <div className="div-icon-baixar-app">

                        <div className='container-lojas'>

                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.br.appedidos&hl=pt&gl=US">

                            <img className="icon-baixar-app" src={googlePlayIcon} alt="Google Play Icon"/>

                        </a>

                        <a target="_blank" href="https://apps.apple.com/br/app/appedidos/id1063395951">

                            <img className="icon-baixar-app" src={appStoreIcon} alt="App Store Icon"/>

                        </a>

                        </div>

                        <div className='copyright-container'> 
                            <span class="copyright-text">
                                Copyright 2024 - Todos os direitos reservados - atendimento@appedidos.com.br <br/>
                                Av. Brasília, 2121, New York Tower, Jardim Nova Yorque, Araçatuba - SP 
                            </span>
                        </div>
                    </div>

                </div>


            </div>
        </div>


    )
    
}

export default Footer